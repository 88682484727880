import { render, staticRenderFns } from "./Detalhes.vue?vue&type=template&id=95bae820&"
import script from "./Detalhes.js?vue&type=script&lang=js&"
export * from "./Detalhes.js?vue&type=script&lang=js&"
import style0 from "./Detalhes.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QNoSsr from 'quasar/src/components/no-ssr/QNoSsr.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QDialog,QBtn,QIcon,QCarousel,QCarouselSlide,QNoSsr,QImg,QTooltip,QForm,QInput,QCheckbox,QCircularProgress,QColor});
